<template lang="pug">
main.d-flex.align-center.px-md-10.py-10.justify-center.justify-md-start
  .page-schools-list.px-10
    school(
      v-for="({ city, img, address, whatsapp, href }, i) in schools",
      :key="i",
      :city="city",
      :img="img",
      :href="href",
      :address-text="address && address.text",
      :address-link="address && address.link",
      :whatsapp-text="whatsapp && whatsapp.text",
      :whatsapp-link="whatsapp && whatsapp.link"
    )
</template>

<script>
import School from "@/components/School";

export default {
  data: () => ({
    schools: [
      {
        city: "SORRISO / MT",
        address: {
          text: "Rua São Silvestre, 1636<br>Jardim Flor do Cerrado - Sorriso-MT",
          link: "https://goo.gl/maps/YujdYvMieUQgm4ot8",
        },
        img: "/img/sorriso.png",
        whatsapp: {
          text: "(66) 9 99656-1297",
          link: "https://wa.me/5566996561297",
        },
        href: "https://www.cspsorriso.com.br",
      },
      // {
      //   city: "CUIABÁ / MT",
      //   img: "/img/cuiaba.png",
      // },
      {
        city: "SINOP / MT",
        address: {
          text: "Av. Magda de C. Pissinatti, Nº 69<br/>Residencial Florença - Sinop-MT",
          link: "https://maps.app.goo.gl/ATcTagMYFrUJS9X1A",
        },
        img: "/img/sinop.png",
        whatsapp: {
          text: "(66) 99963-1919",
          link: "https://wa.me/5566999631919",
        },
        href: "https://www.cspsinop.com.br",
      },
    ],
  }),
  components: {
    School,
  },
};
</script>

<style>
main {
  background: url(/img/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.page-schools-list {
  display: grid;
  grid-template: "I . II" auto;
  justify-content: center;
  gap: 10px 30px;
}

.page-schools-list > :nth-child(1) {
  grid-area: I;
}
.page-schools-list > :nth-child(2) {
  grid-area: II;
}
.page-schools-list > :nth-child(3) {
  grid-area: III;
}
@media screen and (max-width: 960px) {
  .page-schools-list {
    display: grid;
    grid-template: "I" "II" "III";
    gap: 60px;
    margin-left: 0px;
  }
}
@media screen and (min-width: 1264.001px) {
  .page-schools-list {
    margin-left: 200px;
  }
}
</style>
